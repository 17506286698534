
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ReceitaService } from "@/core/services/financeiro";
import { ReceitaParcela } from "@/core/models/financeiro";

@Component
export default class SplitPagamento extends Vue {
  @Prop() public item!: ReceitaParcela;
  @Prop() public value!: string;

  modelo = new Model();
  receitaService = new ReceitaService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  $refs!: {
    form: HTMLFormElement;
  };

  @Watch("modelo")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  Close() {
    this.modelo = new Model();
    this.dialog = false;
  }

  Salvar() {
    if (this.$refs.form.validate()) {
      this.modelo.id = this.item.id;
      this.receitaService
        .AtualizarSplit(this.item.id, this.modelo.type, this.modelo.value)
        .then(
          (res) => {
            this.$swal(
              "Aviso",
              "Split cadastrado com sucesso!",
              res.status == 201 || res.status == 200 ? "success" : "warning"
            );
            this.$emit("salvou");
            this.Close();
          },
          (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning");
            } else {
              this.$swal(
                "Aviso",
                err.response.data,
                err.response.status == 400 ? "warning" : "error"
              );
            }
          }
        );
    }
  }
}

class Model {
  id: number = 0;
  type: string = ""; // Fixed or Percent
  value: number = 0;
}
