
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as jsonpatch from 'fast-json-patch';
import { TabelaValorConsumoAguaService } from "@/core/services/agua";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
import { AdiantamentoCarteira } from "@/core/models/financeiro";
import { AdiantamentoCarteiraService } from "@/core/services/financeiro";
 

@Component
export default class CadastroConsumo extends Vue {
  @Prop() public item!: AdiantamentoCarteira;
  @Prop() public value!: string;

  service = new AdiantamentoCarteiraService();
  valid = true;
  dialog = false;
  step = 1
  parcelOptions = [1, 15, 25, 35, 50, 70, 100, 150];
  listaEmpresa = [];
  ListaSimulacao:any = [];
  selectedDate: Date | null = null;
  editarDataz;
  months:any = [];
  indexMes: number = 0;
 
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement
  }; 
  

  get getNextThreeMonths() {
    const today = new Date();

    for (let i = 1; i <= 3; i++) {
      const monthDate = new Date(today.getFullYear(), today.getMonth() + i, 1);
      this.months.push({
        label: monthDate.toLocaleString('default', { month: 'long', year: 'numeric' }),
        date: monthDate,
      });
    }

    return this.months;
  }


  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation(); 
    }  
  }
  observer! : jsonpatch.Observer<AdiantamentoCarteira>; 

  @Watch("value") 
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);

    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }


  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  } 
   
  Salvar() {
    if (!this.Validacoes()) {

      let valorSimulado = this.ListaSimulacao.find((x:any) => x.quantidadeParcela == this.item.quantidadeParcelas).valorSimulado;
      this.item.valorParcela = valorSimulado;

      let pacthModel = jsonpatch.generate(this.observer); 
      (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close(); 
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })  
    }
  }

   Validacoes(){
    if(this.item.objetivo.length == 0){
      this.$swal("Aviso", "Campo Objetivo não preenchido.", "warning");
      this.step = 1;
      return true; 
    } 
    
    if (!this.item.empresaSolicitante.empresaId) {
      this.$swal("Alerta!", "É necessário selecionar a empresa solicitante.", "warning");
      this.step = 2;
      return true;
    }

    if (this.item.valor <= 1000) {
      this.$swal("Alerta!", " O valor do adiantamento Precisa ser maior que R$ 1.000,00.", "warning");
      this.step = 3;
      return true;
    }

    if(this.item.quantidadeParcelas == 0){
      this.$swal("Aviso", "Escolha a quantidade de Parcelas.", "warning");
      this.step = 4;
      return true; 
    }

    if(!this.item.primeiroMesParcela){
      this.$swal("Aviso", "Escolha o primero mês da Parcela.", "warning");
      this.step = 5;
      return true;
    }

 
  }

onMonthSelect(selectedValue, index) {
    const selectedIndex = this.getNextThreeMonths.findIndex(
      (item) => item.date === selectedValue
    );
    // Aqui você pode usar o índice como necessário
    this.handleMonthSelection(index, selectedIndex); 
  }
  handleMonthSelection(itemIndex, monthIndex) {
    this.indexMes = monthIndex
  }


  Close() {
    this.step = 1;
    this.dialog = false;
  }

  SimularAdiantamento(valor, mes){
      this.service.ObterSimulacao(valor, mes).then(
        res => {
          this.ListaSimulacao = res.data.itens
        }
      )
  }

  mounted(){
    new EmpresaService().ListarTudo().then().then(
      res => {
        this.listaEmpresa = res.data.items;
      }
    )
  
  }
}
