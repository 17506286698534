
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ContaCorrenteService, FormaPagamentoService, ReceitaService, SituacaoReceitaParcelaService } from "@/core/services/financeiro";
import { Receita, ReceitaBaixa, ReceitaParcela } from "@/core/models/financeiro";
import { FormatarNumeroTelefone, SituacaoColor } from '@/assets/scripts/helper';

@Component
export default class AgruparAntecipar extends Vue {
  @Prop() public item!: Receita;
  @Prop() public value!: string;

  agruparParcela = new ReceitaParcela();
  contratoAntecipacao = new ReceitaParcela();
  receitaService = new ReceitaService();

  baixa = new ReceitaBaixa();

   fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  
  dialogBaixa = false;
  valid = true;
  dialog = false;
 
  $refs!: {
    form: HTMLFormElement;
  };

  headers: any[] = [
    {text: "", value: "actions", sortable: false },
    {text: "Nº", value: "parcela", align: "center",sortable: false},
    {text: "Valor da Parcela", value: "valor", align: "center",sortable: false},
    {text: "Boleto", value: "urlBoleto", align: "center",sortable: false},
    {text: "Situação", value: "situacao.nome", align: "center",sortable: false},
    {text: "Vencimento", value: "dataVencimento", align: "center",sortable: false},
  ];

  contaCorrentes = [];
  listaFormaPagamento = [];

  SelecionarParcelas = [];
  parcelasEmAberto = [];
  listaSituacao = [];

  pdfUrl: string = "";
  urlCarne:string = "";
  dialogAgrupar:boolean = false;
  dialogAnteciparContrato:boolean = false;
  loading:boolean = false;
     
  optionsParcelas: any = {
    itemsPerPage: -1
  }; 

  Agrupar(listaParcela){
    if(listaParcela.length <= 1)
      return this.$swal("Aviso", "É necessário selecionar duas ou mais parcelas para fazer o agrupamento de parcelas.", "warning");

    if(listaParcela.find(x=>x.urlBoleto))
      return this.$swal("Aviso", "Não é possível agrupar com boleto gerado.", "warning");
      
    this.agruparParcela = listaParcela;
    this.dialogAgrupar = true;
  } 

  Antecipar(listaParcela){
    if(listaParcela[0] == undefined)
      return this.$swal("Aviso", "É necessário selecionar uma ou mais parcelas.", "warning");

    if(listaParcela.find(x=>x.urlBoleto))
      return this.$swal("Aviso", "Não é possível antecipar com boleto gerado.", "warning");
            
    this.contratoAntecipacao = listaParcela;
    this.dialogAnteciparContrato = true;
  } 

  GerarCarne(listaParcela) {
    //tipoOperacaoId == 3 e codigo do banco for 1

    if (listaParcela.length <= 1) 
      return this.$swal("Aviso", "É necessário selecionar duas ou mais parcelas para gerar o carnê.", "warning");

    this.$swal({
    title: "Atenção!",
    text: "Tem certeza que deseja gerar o carnê?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm: true,
    preConfirm: () => {
      return (this.item.tipoOperacaoId == 3 ? this.receitaService.GerarCarneBancoBrasil(listaParcela.map(x => x.id)) :  this.receitaService.GerarCarne(listaParcela.map(x => x.id))).then(
        (res) => {
          if (res.status == 200){
            this.SelecionarParcelas = [];
            let file = new Blob([res.data], {type: res.headers["content-type"]});
            this.pdfUrl = URL.createObjectURL(file);
            return res;
          } 
        },
        (err) => this.$swal("Aviso", err.response.data, "error")
      );
    },
    //allowOutsideClick: () => !this.$swal.isLoading(),
  }).then((result:any) => {
    if (result.value.status == 200) {
      this.$swal("Aviso", "Operação realizada com sucesso!", "success");
      this.urlCarne = result.value.data;
      } else {
        this.$swal("Aviso", result.value.data, "warning");
      }
    });
  }

  AbrirDialogBaixarParcelas(listaParcela){
    if(listaParcela[0] == undefined)
      return this.$swal("Aviso", "É necessário selecionar uma ou mais parcelas.", "warning");

      this.dialogBaixa = true;
  }

  BaixarParcelas(listaParcela) {
    let modelo = {
      parcelaIds: listaParcela.map(x => x.id),
      modeloBaixa: this.baixa
    }

    this.receitaService.BaixarMultiplasParcelas(modelo).then(
      (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.ObterReceitaId();
        this.Reset()
        this.dialogBaixa = false;
      }, 
        (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }
    )
  }
  
  
  VisualizarBoleto(item){
    if(item.tipoGatewayId  == 3){
     let routeLink = this.$router.resolve({ 
        name: "RelBoletoSafra",
          query: { 
            codigoZoop: item.codigoZoop ? item.codigoZoop.toString() : "",
          },
        }); 
      window.open(routeLink.href, "_blank");
    }else{
      window.open(item.urlBoleto);
    }
  } 

  VisualizarCarne(item){
    if(item.tipoOperacaoId == 3){
      window.open(this.pdfUrl);
    }
    else{
      window.open(this.urlCarne);
    } 
}

  VisualizarCarneWpp(){
    var telefone = FormatarNumeroTelefone(this.item.cliente!.telefone);

    window.open(`https://api.whatsapp.com/send?phone=55${telefone}&text=Olá, Segue a baixo o link do seu carnê: ${this.urlCarne}`)
  }
  
  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  ObterReceitaId(){
    this.loading = true;
    this.receitaService.ObterPorId(this.item.id, 'Cliente, Parcelas.Baixas').then(
      res => {
        this.atualizarParcelas(res.data.parcelas);
        this.Reset();
        this.loading = false;
      }
    )
  }

  atualizarParcelas(parcelas) {
    const filtrarParcelas = parcela => (parcela.agrupadorId === null && parcela.situacaoId !== 3 && parcela.situacaoId !== 2 && parcela.isAgrupador === false);
    this.item.parcelas = parcelas.filter(filtrarParcelas);
  }

  Reset(){
    this.SelecionarParcelas = [];
    this.baixa = new ReceitaBaixa();
  }

  Close() {
    this.urlCarne = "";
    this.parcelasEmAberto = []; 
    this.Reset();
    this.dialog = false;
  }

  SituacaoColor(item){
    return SituacaoColor(item)
  }

  mounted(){
    new ContaCorrenteService().ListarTudo().then(res => {
      this.contaCorrentes = res.data.items;
    })

    new FormaPagamentoService().ListarTudo().then(
    (res) => {
      this.listaFormaPagamento = res.data.items;
    });

    new SituacaoReceitaParcelaService().ListarTudo().then(
      res => {this.listaSituacao = res.data.items}
    )
  }
}
